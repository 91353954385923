import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultErrorHeadline: {
    id: 'errorBoundary.defaultErrorHeadline',
    defaultMessage: 'Something went wrong!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessage: {
    id: 'sambla.no.errorBoundary.defaultErrorMessage',
    defaultMessage: 'We currently have technical problems. Please contact customer support for assistance or try again!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessageWithPhoneNumber: {
    id: 'sambla.no.errorBoundary.defaultErrorMessageWithPhoneNumber',
    defaultMessage: 'We currently have technical problems. Click the button below and we will call you as soon as we can on <i> {customerPhoneNumber} </i> to continue your application',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  emailSubmissionInputPlaceholder: {
    id: 'sambla.no.errorBoundary.emailSubmissionInputPlaceholder',
    defaultMessage: 'Your email adress',
    description: 'The default input placeholder for Email Submission form',
  },
  emailSubmissionButtonLabel: {
    id: 'sambla.no.errorBoundary.emailSubmissionButtonLabel',
    defaultMessage: 'Email Submission',
    description: 'The default button label for Email Submission form',
  },
  emailSubmissionSuccessMessage: {
    id: 'sambla.no.errorBoundary.emailSubmissionSuccessMessage',
    defaultMessage: 'Thank you!',
    description: 'Success message on the "Email Submission"-button in the form',
  },
  emailSubmissionErrorMessage: {
    id: 'sambla.no.errorBoundary.emailSubmissionErrorMessage',
    defaultMessage: 'We could not send your email adress, please try again later!',
    description: 'Error message on the "Email Submission"-button in the form',
  },
  safeAndSecure: {
    id: 'sambla.no.safeAndSecure',
    defaultMessage: 'Safe and secure',
    description: 'Label for lock seal on the bottom of the form',
  },
  otherIncomeTooHigh: {
    id: 'sambla.no.otherIncomeTooHigh',
    defaultMessage: 'Other income must not be more than {maxIncome, number, currency} NOK',
    description: 'Error message informing the applicant that additional monthly income is too high',
  },
  coApplicantOtherIncomeTooHgih: {
    id: 'sambla.no.coApplicantOtherIncomeTooHgih',
    defaultMessage: 'Other income must not be more than {maxIncome, number, currency} NOK.',
    description: 'Error message informing the applicant that co-applicants additional monthly income is too high',
  },
  tooHighCurrentLoan: {
    id: 'sambla.no.tooHighCurrentLoan',
    defaultMessage: 'Highest loan amount is {maxLimit, number, currency}.',
    description: 'Error message informing the applicant that loan amount is to high',
  },
  tooHighCurrentLoanMonthlyCost: {
    id: 'sambla.no.tooHighCurrentLoanMonthlyCost',
    defaultMessage: 'Highest monthly cost is {maxLimit, number, currency}.',
    description: 'Error message informing the applicant that loan amount is to high',
  },
  otherIncomeBeforeTaxRequired: {
    id: 'sambla.no.otherMonthlyIncomeBeforeTaxRequired',
    defaultMessage: 'Please provide your other monthly income before taxes (NOK/mth)',
    description: 'Error message informing the applicant that additional monthly income before taxes must be provided',
  },
  otherIncomeAfterTaxRequired: {
    id: 'sambla.no.otherMonthlyIncomeAfterTaxRequired',
    defaultMessage: 'Please provide your other monthly income after taxes (NOK/mth)',
    description: 'Error message informing the applicant that additional monthly income after taxes must be provided',
  },
  otherIncomeOnlyNumbers: {
    id: 'sambla.no.otherMonthlySalaryOnlynumbers',
    defaultMessage: 'Please write your other monthly income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  coApplicantOtherIncomeBeforeTaxRequired: {
    id: 'sambla.no.coApplicantOtherMonthlyIncomeBeforeTaxRequired',
    defaultMessage: "Please provide your co-applicant's other monthly income before taxes (NOK/mth)",
    description: "Error message informing the co-applicant's additional monthly income before taxes must be provided",
  },
  coApplicantOtherIncomeAfterTaxRequired: {
    id: 'sambla.no.coApplicantOtherMonthlyIncomeAfterTaxRequired',
    defaultMessage: "Please provide your co-applicant's other monthly income after taxes (NOK/mth)",
    description: "Error message informing the co-applicant's additional monthly income after taxes must be provided",
  },
  coApplicantOtherIncomeOnlyNumbers: {
    id: 'sambla.no.coApplicantOtherMonthlySalaryOnlynumbers',
    defaultMessage: "Please write your co-applicant's other monthly income in numbers only",
    description: 'Error message informing the customer that only numbers are allowed to be passed in for their co-applicant',
  },
  coApplicantRentalMonthlyIncomeBeforeTaxPlaceholder: {
    id: 'sambla.no.coApplicantRentalMonthlyIncomeBeforeTaxPlaceholder',
    defaultMessage: 'Rental income per month',
    description: '',
  },
  coApplicantRentalMonthlyIncomeBeforeTaxLabel: {
    id: 'sambla.no.coApplicantRentalMonthlyIncomeBeforeTaxLabel',
    defaultMessage: 'Rental income per month',
    description: '',
  },
  coApplicantHasRentalIncomeTooltip: {
    id: 'sambla.no.coApplicantHasRentalIncomeTooltip',
    defaultMessage: 'Do your co-applicant get paid by renting out a room, an apartment or other accommodation?',
    description: '',
  },
  coApplicantHasRentalIncomeLabel: {
    id: 'sambla.no.coApplicantHasRentalIncomeLabel',
    defaultMessage: 'Do your co-applicant receive rental income?',
    description: '',
  },
  rentalMonthlyIncomeBeforeTaxPlaceholder: {
    id: 'sambla.no.rentalMonthlyIncomeBeforeTaxPlaceholder',
    defaultMessage: 'Rental income per month',
    description: '',
  },
  rentalMonthlyIncomeBeforeTaxLabel: {
    id: 'sambla.no.rentalMonthlyIncomeBeforeTaxLabel',
    defaultMessage: 'Rental income per month',
    description: '',
  },
  hasRentalIncomeTooltip: {
    id: 'sambla.no.hasRentalIncomeTooltip',
    defaultMessage: 'Do you get paid by renting out a room, an apartment or other accommodation?',
    description: '',
  },
  hasRentalIncomeLabel: {
    id: 'sambla.no.hasRentalIncomeLabel',
    defaultMessage: 'Do you receive rental income?',
    description: '',
  },
  coApplicantMonthlyIncomeAfterTaxPlaceholder: {
    id: 'sambla.no.coApplicantMonthlyIncomeAfterTaxPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Placeholder for coApplicant monthly income after tax inside form',
  },
  coApplicantMonthlyIncomeAfterTaxLabel: {
    id: 'sambla.no.coApplicantMonthlyIncomeAfterTaxLabel',
    defaultMessage: "Co-applicant's monthly income after taxes (NOK/mth)",
    description: 'Label for coApplicant monthly income after tax inside form',
  },
  coApplicantMonthlyIncomeBeforeTaxTooltip: {
    id: 'sambla.no.coApplicantMonthlyIncomeBeforeTaxTooltip',
    defaultMessage: "Please enter your co-applicant's total monthly income from all sources (NOK before taxes). This may include for example salary, commission or pension.",
    description: 'Tooltip for coApplicant income field',
  },
  coApplicantMonthlyIncomeBeforeTaxPlaceholder: {
    id: 'sambla.no.coApplicantMonthlyIncomeBeforeTaxPlaceholder',
    defaultMessage: 'XXX XXX',
    description: 'Placeholder for coApplicant monthly income before tax inside form',
  },
  coApplicantAnnualIncomeBeforeTaxLabel: {
    id: 'sambla.no.coApplicantAnnualIncomeBeforeTaxLabel',
    defaultMessage: 'Co-applicant’s annual income before taxes (NOK)',
    description: 'Label for coApplicant annual income before tax inside form',
  },
  coApplicantNetOtherIncomeLabel: {
    id: 'sambla.no.coApplicantNetOtherIncomeLabel',
    defaultMessage: "Co-applicant's other income after tax (NOK/mth)",
    description: 'Asking customer what their other income after tax is',
  },
  coApplicantNetOtherIncomePlaceholder: {
    id: 'sambla.no.coApplicantNetOtherIncomePlaceholder',
    defaultMessage: 'X XXX',
    description: 'Asking customer what their other income after tax is',
  },
  coApplicantOtherIncomeTooltip: {
    id: 'sambla.no.coApplicantOtherIncomeTooltip',
    defaultMessage: 'Does the co-applicant have additional sources of income that are not stated already? This may include for example income from letting an apartment, income from a hobby, or state allowance. They need to prove their additional income through tax or payment slips',
    description: 'Tooltip for coApplicant has other income',
  },
  coApplicantOtherIncomeLabel: {
    id: 'sambla.no.coApplicantOtherIncomeLabel',
    defaultMessage: 'Does your co-applicant have any other income?',
    description: 'Asking if coApplicant has other income',
  },
  slideTitleFive: {
    id: 'sambla.no.slideTitleFive',
    defaultMessage: "Co-applicant's details",
    description: '',
  },
  houseLoanAmountTooltip: {
    id: 'sambla.no.houseLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your house loan/loans. Do not list other debts in this field.',
    description: '',
  },
  creditLoanAmountTooltip: {
    id: 'sambla.no.creditLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your credit card loan/loans. Do not list other debts in this field.',
    description: '',
  },
  studentLoanAmountTooltip: {
    id: 'sambla.no.studentLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your student loan/loans. Do not list other debts in this field.',
    description: '',
  },
  privateLoanAmountTooltip: {
    id: 'sambla.no.privateLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your consumer loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantPrivateLoansAmountTooltip: {
    id: 'sambla.no.coApplicantPrivateLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants consumer loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantCarLoansAmountTooltip: {
    id: 'sambla.no.coApplicantCarLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants car loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantHouseLoansAmountTooltip: {
    id: 'sambla.no.coApplicantHouseLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants house loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantStudentLoansAmountTooltip: {
    id: 'sambla.no.coApplicantStudentLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants student loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantCreditCardLoansAmountTooltip: {
    id: 'sambla.no.coApplicantCreditCardLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants credit card loan/loans. Do not list other debts in this field.',
    description: '',
  },
  houseLoanMonthlyCostTooltip: {
    id: 'sambla.no.houseLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your house loan/loans. Do not list other costs in this field.',
    description: '',
  },
  studentLoanMonthlyCostTooltip: {
    id: 'sambla.no.studentLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your student loan/loans. Do not list other costs in this field.',
    description: '',
  },
  privateLoanMonthlyCostTooltip: {
    id: 'sambla.no.privateLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your consumer loan/loans. Do not list other costs in this field.',
    description: '',
  },
  carLoansMonthlyCostTooltip: {
    id: 'sambla.no.carLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your car loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantPrivateLoansMonthlyCostTooltip: {
    id: 'sambla.no.coApplicantPrivateLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants consumer loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantCarLoansMonthlyCostTooltip: {
    id: 'sambla.no.coApplicantCarLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants car loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantHouseLoansMonthlyCostTooltip: {
    id: 'sambla.no.coApplicantHouseLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants house loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantStudentLoansMonthlyCostTooltip: {
    id: 'sambla.no.coApplicantStudentLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants student loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantCreditCardLoansMonthlyCostTooltip: {
    id: 'sambla.no.coApplicantCreditCardLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants credit loan/loans. Do not list other costs in this field.',
    description: '',
  },
  houseLoanTooltip: {
    id: 'sambla.no.houseLoanTooltip',
    defaultMessage: 'Please enter the current debt of your mortgage. House loans/mortgages are loans used for financing the purchase of a property. These are loans that have the property itself as security. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current house loans',
  },
  studentLoanTooltip: {
    id: 'sambla.no.studentLoanTooltip',
    defaultMessage: 'Please enter the current debt of your student loan. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current student loans',
  },
  carLoanTooltip: {
    id: 'sambla.no.carLoanTooltip',
    defaultMessage: 'Please specify if you have loans to finance purchases of vehicles and have the vehicle itself as security for the loan. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current car loans',
  },
  carLoanAmountTooltip: {
    id: 'sambla.no.carLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your car loan/loans. Do not list other debts in this field.',
    description: 'Tooltip regarding filling in current car loans',
  },
  creditLoanTooltip: {
    id: 'sambla.no.creditLoanTooltip',
    defaultMessage: 'Please enter the current debt of your credit card/cards. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current credit loans',
  },
  privateLoanTooltip: {
    id: 'sambla.no.privateLoanTooltip',
    defaultMessage: 'Please enter your current debt for consumer/private loan/loans. Consumer loans are loans without security that can be used for anything - usually for consumption, renovation or paying other expensive debts. The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current private loans',
  },
  privateLoansLabel: {
    id: 'sambla.no.privateLoansLabel',
    defaultMessage: 'Do you have consumer loans?',
    description: 'private loans question/label',
  },
  privateLoansAmountLabel: {
    id: 'sambla.no.privateLoansAmountLabel',
    defaultMessage: 'Consumer loan amount (NOK)',
    description: 'private loans amount',
  },
  privateLoansMonthlyCostPlaceholder: {
    id: 'sambla.no.privateLoansMonthlyCostPlaceholder',
    defaultMessage: 'Consumer loan monthly cost (NOK/mth)',
    description: 'private loans monthly cost',
  },
  privateLoansMonthlyCostLabel: {
    id: 'sambla.no.privateLoansMonthlyCostLabel',
    defaultMessage: 'Consumer loan monthly cost (NOK/mth)',
    description: 'private loans monthly cost',
  },
  creditLoansLabel: {
    id: 'sambla.no.creditLoansLabel',
    defaultMessage: 'Do you have credit loans? (NOK)',
    description: 'Asking customer if they have credit loans',
  },
  creditLoansAmountLabel: {
    id: 'sambla.no.creditLoansAmountLabel',
    defaultMessage: 'Credit loan amount (NOK)',
    description: 'Credit loan amount label',
  },
  creditLoansAmountPlaceholder: {
    id: 'sambla.no.creditLoansAmountPlaceholder',
    defaultMessage: 'Credit loan amount (NOK)',
    description: 'Credit loan amount placeholder',
  },
  creditLoansMonthlyCostPlaceholder: {
    id: 'sambla.no.creditLoansMonthlyCostPlaceholder',
    defaultMessage: 'Credit loan monthly cost (NOK/mth)',
    description: 'Credit loan monthly cost placeholder',
  },
  houseLoansLabel: {
    id: 'sambla.no.houseLoansLabel',
    defaultMessage: 'Do you have any house loans?',
    description: 'House loan label/questions',
  },
  houseLoansAmountLabel: {
    id: 'sambla.no.houseLoansAmountLabel',
    defaultMessage: 'House loans amount (NOK)',
    description: 'House loan amount label',
  },
  houseLoansAmountPlaceholder: {
    id: 'sambla.no.houseLoansAmountPlaceholder',
    defaultMessage: 'House loans amount (NOK)',
    description: 'House loan amount placeholder',
  },
  newPurposeHeaderText: {
    id: 'sambla.no.newPurposeHeaderText',
    defaultMessage: 'Loan purpose for the new loan part',
    description: 'Header in the application form for purpose of the part of the loan that is considered new loan',
  },
  houseLoansMonthlyCostLabel: {
    id: 'sambla.no.houseLoansMonthlyCostLabel',
    defaultMessage: 'House loans monthly cost (NOK/mth)',
    description: 'House loan MonthlyCost label',
  },
  houseLoansMonthlyCostPlaceholder: {
    id: 'sambla.no.houseLoansMonthlyCostPlaceholder',
    defaultMessage: 'House loans monthly cost (NOK/mth)',
    description: 'House loan MonthlyCost placeholder',
  },
  houseLoansPartLabel: {
    id: 'sambla.no.houseLoansPartLabel',
    defaultMessage: 'How much is shared with your partner?',
    description: 'Asking customer how much of the house loan they pay',
  },
  carLoansPartLabel: {
    id: 'sambla.no.carLoansPartLabel',
    defaultMessage: 'How much is shared with your partner?',
    description: 'Asking customer how much of the car loan they pay',
  },
  carLoansLoanIsOwnedByApplicant: {
    id: 'sambla.no.carLoansLoanIsOwnedByApplicant',
    defaultMessage: 'I am responsible',
    description: 'Text for applicant is owning the car loan',
  },
  carLoansLoanIsSharedWithCoApplicant: {
    id: 'sambla.no.carLoansLoanIsSharedWithCoApplicant',
    defaultMessage: 'Shared responsibility',
    description: 'Text for the car loan is shared with co applicant',
  },
  houseLoansLoanIsOwnedByApplicant: {
    id: 'sambla.no.houseLoansLoanIsOwnedByApplicant',
    defaultMessage: 'I am responsible',
    description: 'Text for applicant is owning the loan',
  },
  houseLoansPartHalf: {
    id: 'sambla.no.houseLoansPartHalf',
    defaultMessage: '50%',
    description: 'Paying 50% of the house loan',
  },
  houseLoansLoanIsSharedWithCoApplicant: {
    id: 'sambla.no.houseLoansLoanIsSharedWithCoApplicant',
    defaultMessage: 'Shared responsibility',
    description: 'Text for the house loan is shared with co applicant',
  },
  carsOwnedLabel: {
    id: 'sambla.no.mortgage.carsOwnedLabel',
    defaultMessage: 'How many cars do you own?',
    description: 'Label for number of cars owned',
  },
  carLoansLabel: {
    id: 'sambla.no.carLoansLabel',
    defaultMessage: 'Do you have car loans?',
    description: 'Asking customer if they have car loans',
  },
  carLoansAmountLabel: {
    id: 'sambla.no.carLoansAmountLabel',
    defaultMessage: 'Car loans total amount (NOK)',
    description: 'Label car loans amount',
  },
  carLoansAmountPlaceholder: {
    id: 'sambla.no.carLoansAmountPlaceholder',
    defaultMessage: 'Car loans total amount (NOK)',
    description: 'Placeholder car loans amount',
  },
  carLoansMonthlyCostLabel: {
    id: 'sambla.no.carLoansMonthlyCostLabel',
    defaultMessage: 'Car loans monthly cost (NOK/mth)',
    description: 'Label car loans monthly cost',
  },
  carLoansMonthlyCostPlaceholder: {
    id: 'sambla.no.carLoansMonthlyCostPlaceholder',
    defaultMessage: 'Car loans monthly cost (NOK/mth)',
    description: 'Placeholder car loans monthly cost',
  },
  studentLoansLabel: {
    id: 'sambla.no.studentLoansLabel',
    defaultMessage: 'Do you have student loans? (NOK)',
    description: 'Asking customer if they have student loans',
  },
  studentLoansAmountLabel: {
    id: 'sambla.no.studentLoansAmountLabel',
    defaultMessage: 'Student loans total amount (NOK)',
    description: 'Label student loans amount',
  },
  studentLoansAmountPlaceholder: {
    id: 'sambla.no.studentLoansAmountPlaceholder',
    defaultMessage: 'Student loans total amount (NOK)',
    description: 'Placeholder student loans amount',
  },
  studentLoansMonthlyCostLabel: {
    id: 'sambla.no.studentLoansMonthlyCostLabel',
    defaultMessage: 'Student loans monthly cost (NOK/mth)',
    description: 'Label student loans monthly cost',
  },
  studentLoansMonthlyCostPlaceholder: {
    id: 'sambla.no.studentLoansMonthlyCostPlaceholder',
    defaultMessage: 'Student loans monthly cost (NOK/mth)',
    description: 'Placeholder student loans monthly cost',
  },
  currentLoansInformation: {
    id: 'sambla.no.currentLoansInformation',
    defaultMessage: 'NOTE: Please only list the specific loans we are asking for. Any other loans are not relevant at this stage and might be asked at a later stage if necessary.',
    description: 'Information regarding filling in current loans',
  },
  moveInDateLabel: {
    id: 'sambla.no.moveInDateLabel',
    defaultMessage: 'When did you move to your address? (year)',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDateLabelCoApplicant: {
    id: 'sambla.no.moveInDateLabelCoApplicant',
    defaultMessage: 'When did your co-applicant move to their address? (year)',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDatePlaceholder: {
    id: 'sambla.no.moveInDatePlaceholder',
    defaultMessage: 'E.g 2002',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDatePlaceholderCoApplicant: {
    id: 'sambla.no.moveInDatePlaceholderCoApplicant',
    defaultMessage: 'E.g 2002',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDateRequiredYear: {
    id: 'sambla.no.moveInDateRequiredYear',
    defaultMessage: 'Please enter the year you moved to your current home',
    description: 'Asking customer when they moved to their current home',
  },
  coAppMoveInDateYear: {
    id: 'sambla.no.coAppMoveInDateYear',
    defaultMessage: 'Please enter the year your co-applicant moved to their current home',
    description: 'Asking customer when their co-applicant moved to their current home',
  },
  moveInDateOnlyNumbers: {
    id: 'sambla.no.moveInDateOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year they moved to Finland',
  },
  moveInDateFourDigits: {
    id: 'sambla.no.moveInDateFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year they moved to Finland" should be filled in with four digits',
  },
  moveInDateTooFarBack: {
    id: 'sambla.no.moveInDateTooFarBack',
    defaultMessage: 'Enter the year you moved to your current home (e.g. 2010).',
    description: 'Error message informing the applicant that the year they moved to their current home is set to far back in time',
  },
  coApplicantMoveInDateTooFarBack: {
    id: 'sambla.no.coApplicant.coApplicantMoveInDateTooFarBack',
    defaultMessage: 'Enter the year they moved to their current home (e.g. 2010).',
    description: 'Error message informing the coapplicant moved to their current home is to far back in time',
  },
  moveInDateInTheFuture: {
    id: 'sambla.no.moveInDateInTheFuture',
    defaultMessage: 'Your move date cannot be in the future',
    description: 'Error message informing the applicant that their "move to Finland date" cannot be set to a date in the future',
  },
  coAppMoveInDateInTheFuture: {
    id: 'sambla.no.coAppMoveInDateInTheFuture',
    defaultMessage: 'Their move date cannot be in the future.',
    description: 'Error message informing the coapplicant that their "move to Finland date" cannot be set to a date in the future',
  },
  otherIncomeSourceLabel: {
    id: 'sambla.no.otherIncomeSourceLabel',
    defaultMessage: 'Other income source',
    description: 'Asking customer what their other income after tax is',
  },
  otherIncomeSourcePlaceholder: {
    id: 'sambla.no.otherIncomeSourcePlaceholder',
    defaultMessage: 'E.g second job',
    description: 'Asking customer what their other income after tax is',
  },
  netOtherIncomeLabel: {
    id: 'sambla.no.netOtherIncomeLabel',
    defaultMessage: 'Other income after tax (NOK/mth)',
    description: 'Asking customer what their other income after tax is',
  },
  netOtherIncomePlaceholder: {
    id: 'sambla.no.netOtherIncomePlaceholder',
    defaultMessage: 'X XXX',
    description: 'Asking customer what their other income after tax is',
  },
  monthlyIncomeAfterTaxPlaceholder: {
    id: 'sambla.no.monthlyIncomeAfterTaxPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Placeholder for applicant monthly income after tax inside form',
  },
  monthlyIncomeAfterTaxLabel: {
    id: 'sambla.no.monthlyIncomeAfterTaxLabel',
    defaultMessage: 'Monthly income after taxes (NOK/mth)',
    description: 'Label for applicant monthly income after tax inside form',
  },
  monthlySalaryPlaceholder: {
    id: 'sambla.no.monthlySalaryPlaceholder',
    defaultMessage: 'XXX XXX',
    description: 'Placeholder for applicant monthly income before tax inside form',
  },
  monthlySalaryLabel: {
    id: 'sambla.no.monthlySalaryLabel',
    defaultMessage: 'Monthly income before taxes (NOK/mth)',
    description: 'Label for applicant monthly income before tax inside form',
  },
  annualIncomeLabel: {
    id: 'sambla.no.annualIncomeLabel',
    defaultMessage: 'Annual income before taxes',
    description: 'Label for applicant monthly income before tax inside form',
  },
  otherIncomeTooltip: {
    id: 'sambla.no.otherIncomeTooltip',
    defaultMessage: 'Do you have additional sources of income that are not stated already? This may include for example income from letting an apartment, income from a hobby, or state allowance. You need to prove your additional income through tax or payment slips',
    description: 'Tooltip-text for asking the customer if they have any other income',
  },
  otherIncomeLabel: {
    id: 'sambla.no.otherIncomeLabel',
    defaultMessage: 'Do you have any other income?',
    description: 'Label-text for asking the customer if they have any other income',
  },
  coApplicantBusinessIdPlaceholder: {
    id: 'sambla.no.coApplicantBusinessIdPlaceholder',
    defaultMessage: '1234567-8',
    description: 'Placeholder for coApplicant field Business ID',
  },
  studentSinceDynamicLabel: {
    id: 'sambla.no.studentSinceDynamicLabel',
    defaultMessage: 'Student since (year)',
    description: 'Label for employed since if student',
  },
  coApplicantEducationLabel: {
    id: 'sambla.no.coApplicantEducationLabel',
    defaultMessage: 'Co-applicant’s Education',
    description: 'Label for coapplicant education section',
  },
  creditLoanMonthlyCostTooltip: {
    id: 'sambla.no.creditLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your credit loan/loans. Do not list other costs in this field.',
    description: '',
  },
  creditLoansMonthlyCostLabel: {
    id: 'sambla.no.creditLoansMonthlyCostLabel',
    defaultMessage: 'Credit loan monthly cost (NOK/mth)',
    description: 'Credit loan monthly cost label',
  },
  privateLoansAmountPlaceholder: {
    id: 'sambla.no.privateLoansAmountPlaceholder',
    defaultMessage: 'Consumer loan amount (NOK)',
    description: 'private loans amount',
  },
  selfEmployedEmployerNameDynamicLabel: {
    id: 'sambla.no.selfEmployedEmployerNameDynamicLabel',
    defaultMessage: 'Name of company',
    description: 'Label for employer if self employed',
  },
  studentSinceMonthDynamicLabel: {
    id: 'sambla.no.studentSinceMonthDynamicLabel',
    defaultMessage: 'Student since (month)',
    description: 'Label for employed since month if student',
  },
  selfEmployedSinceMonthDynamicLabel: {
    id: 'sambla.no.selfEmployedSinceMonthDynamicLabel',
    defaultMessage: 'Self employed since (month)',
    description: 'Label for employed since month if self employed',
  },
  unemployedSinceDynamicLabel: {
    id: 'sambla.no.unemployedSinceDynamicLabel',
    defaultMessage: 'Unemployed since',
    description: 'Dynamic label for employed since',
  },
  studentSinceYearDynamicLabel: {
    id: 'sambla.no.studentSinceYearDynamicLabel',
    defaultMessage: 'Student since (year)',
    description: 'Label for employed since if student',
  },
  selfEmployedSinceYearDynamicLabel: {
    id: 'sambla.no.selfEmployedSinceYearDynamicLabel',
    defaultMessage: 'Self employed since (year)',
    description: 'Education label',
  },
  educationLabel: {
    id: 'sambla.no.educationLabel',
    defaultMessage: 'Education',
    description: 'Education label',
  },
  educationTypeMasters: {
    id: 'sambla.no.educationTypeMasters',
    defaultMessage: 'University > 3 years',
    description: 'Education type option',
  },
  educationTypeBachelor: {
    id: 'sambla.no.educationTypeBachelor',
    defaultMessage: 'University < 3 years',
    description: 'Education type option',
  },
  educationTypeHighSchool: {
    id: 'sambla.no.educationTypeHighSchool',
    defaultMessage: 'High school',
    description: 'Education type option',
  },
  educationTypePrimarySchool: {
    id: 'sambla.no.educationTypePrimarySchool',
    defaultMessage: 'Primary school',
    description: 'Education type option',
  },
  yearONE: {
    id: 'sambla.no.yearONE',
    defaultMessage: '1 year',
    description: 'repayment time 1 year',
  },
  yearTWO: {
    id: 'sambla.no.yearTWO',
    defaultMessage: '2 years',
    description: 'repayment time 2 years',
  },
  yearTHREE: {
    id: 'sambla.no.yearTHREE',
    defaultMessage: '3 years',
    description: 'repayment time 3 years',
  },
  yearFOUR: {
    id: 'sambla.no.yearFOUR',
    defaultMessage: '4 years',
    description: 'repayment time 4 year',
  },
  yearFIVE: {
    id: 'sambla.no.yearFIVE',
    defaultMessage: '5 years',
    description: 'repayment time 5 years',
  },
  yearSIX: {
    id: 'sambla.no.yearSIX',
    defaultMessage: '6 years',
    description: 'repayment time 6 years',
  },
  yearSEVEN: {
    id: 'sambla.no.yearSEVEN',
    defaultMessage: '7 years',
    description: 'repayment time 7 year',
  },
  yearEIGHT: {
    id: 'sambla.no.yearEIGHT',
    defaultMessage: '8 years',
    description: 'repayment time 8 years',
  },
  yearNINE: {
    id: 'sambla.no.yearNINE',
    defaultMessage: '9 years',
    description: 'repayment time 9 years',
  },
  yearTEN: {
    id: 'sambla.no.yearTEN',
    defaultMessage: '10 years',
    description: 'repayment time 10 year',
  },
  yearELEVEN: {
    id: 'sambla.no.yearELEVEN',
    defaultMessage: '11 years',
    description: 'repayment time 11 years',
  },
  yearTWELVE: {
    id: 'sambla.no.yearTWELVE',
    defaultMessage: '12 years',
    description: 'repayment time 12 years',
  },
  yearTHIRTEEN: {
    id: 'sambla.no.yearTHIRTEEN',
    defaultMessage: '13 years',
    description: 'repayment time 13 year',
  },
  yearFOURTEEN: {
    id: 'sambla.no.yearFOURTEEN',
    defaultMessage: '14 years',
    description: 'repayment time 14 years',
  },
  yearFIFTEEN: {
    id: 'sambla.no.yearFIFTEEN',
    defaultMessage: '15 years',
    description: 'repayment time 15 years',
  },
  totalLoanUseOnlyNumbers: {
    id: 'sambla.no.totalLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Informing the customer/applicant that only numbers are allowed for the total loan amount',
  },
  tooLowTotalLoan: {
    id: 'sambla.no.tooLowTotalLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to low. Also show what the minimum loan amount is',
  },
  textInputIsRequired: {
    id: 'sambla.no.textInputIsRequired',
    defaultMessage: 'This field can not be left empty',
    description: '',
  },
  buttonGroupIsRequired: {
    id: 'sambla.no.buttonGroupIsRequired',
    defaultMessage: 'Please select one.',
    description: 'Tells the customer that one option needs to be selected',
  },
  checkBoxIsRequired: {
    id: 'sambla.no.checkBoxIsRequired',
    defaultMessage: 'Please consent to our terms and conditions',
    description: 'Tells the customer that checkox needs to be checked',
  },
  repaymentTimeUseOnlyNumbers: {
    id: 'sambla.no.repaymentTimeUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer that they are only allowed to use numbers',
  },
  tooShortRepaymentTime: {
    id: 'sambla.no.tooShortRepaymentTime',
    defaultMessage: 'Shortest possible repayment time is {loanMinYears} year.',
    description: 'Inform the customer that they have choosen a repayment time shorter than allowed',
  },
  tooLongRepaymentTime: {
    id: 'sambla.no.tooLongRepaymentTime',
    defaultMessage: 'Longest possible repayment time is {loanMaxYears} years.',
    description: 'Inform the customer thath they have choosen a repayment time further ahead than allowed',
  },
  purposeIsRequired: {
    id: 'sambla.no.purposeIsRequired',
    defaultMessage: 'Please choose a loan purpose.',
    description: 'Informing the customer that a cause/reason for the loan must be passed in',
  },
  numCurrentLoansIsRequired: {
    id: 'sambla.no.numCurrentLoansIsRequired',
    defaultMessage: 'Number of current loans are required.',
    description: 'Error message informing the applicant that the number of current loan must be provided',
  },
  numCurrentLoansUseOnlyNumbers: {
    id: 'sambla.no.numCurrentLoansUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed',
  },
  tooLowNumCurrentLoans: {
    id: 'sambla.no.tooLowNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loans provided is to low',
  },
  tooHighNumCurrentLoans: {
    id: 'sambla.no.tooHighNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loands provided is to high',
  },
  newLoanUseOnlyNumbers: {
    id: 'sambla.no.newLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer/applicant that they are only allowed to use numbers',
  },
  tooLowNewLoan: {
    id: 'sambla.no.tooLowNewLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount lower than minimum amount allowed',
  },
  tooHighTotalLoan: {
    id: 'sambla.no.tooHighTotalLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to high. Also show what the maximum loan amount is',
  },
  mergeLoanUseOnlyNumbers: {
    id: 'sambla.no.mergeLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed to be provided',
  },
  tooLowMergeLoan: {
    id: 'sambla.no.tooLowMergeLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to low and what the minimum requirements are',
  },
  tooHighMergeLoan: {
    id: 'sambla.no.tooHighMergeLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to high and what the maximum requirements are',
  },
  mergeLoanIsNotABoolean: {
    id: 'sambla.no.mergeLoanIsNotABoolean',
    defaultMessage: 'Please choose if you want to consolidate loans',
    description: 'Error message informing that merge is not of type boolean',
  },
  interestRateUseOnlyNumbers: {
    id: 'sambla.no.interestRateUseOnlyNumbers',
    defaultMessage: 'Please use only numbers',
    description: 'Informing the customer/applicant that only numbers are allowed for the interest rate',
  },
  tooLowInterestRate: {
    id: 'sambla.no.tooLowInterestRate',
    defaultMessage: 'The lowest effective interest rate you can use is {minAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to low. Also show what the minimum interest rate is',
  },
  tooHighInterestRate: {
    id: 'sambla.no.tooHighInterestRate',
    defaultMessage: 'The highest effective interest rate you can use is {maxAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to high. Also show what the maximum interest rate is',
  },
  interestRateHasTwoDots: {
    id: 'sambla.no.interestRateHasTwoDots',
    defaultMessage: "Please check if you've added too many dots as separators",
    description: 'Informing the customer/applicant that they have...',
  },
  nothingBeforeDotInInterestRate: {
    id: 'sambla.no.nothingBeforeDotInInterestRate',
    defaultMessage: 'Please enter a number before the separator',
    description: 'Informing the customer/applicant that they have...',
  },
  tooManyDecimalsInInterestRate: {
    id: 'sambla.no.tooManyDecimalsInInterestRate',
    defaultMessage: "Please check if you've added too many decimals",
    description: 'Informing the customer/applicant that they have...',
  },
  homeTypeIsRequired: {
    id: 'sambla.no.homeTypeIsRequired',
    defaultMessage: 'Please enter a housing situation.',
    description: 'Error message informing applicant that a type of housing must be provided',
  },
  employmentTypeIsRequired: {
    id: 'sambla.no.employmentTypeIsRequired',
    defaultMessage: 'Please enter an employment type',
    description: 'Inform the customer that a employment type must be provided',
  },
  employedToMonthIsRequired: {
    id: 'sambla.no.employedToMonthIsRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed until"-month must be selected',
  },
  employedToRequiredYear: {
    id: 'sambla.no.employedToRequiredYear',
    defaultMessage: 'Year is required.',
    description: 'Error message informing the applicant that a year must be filled in for the field "employed untill the year of"',
  },
  employedToRequiredMonth: {
    id: 'sambla.no.employedToRequiredMonth',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that a month must be filled in for the field "employed untill the month of',
  },
  employedToUseOnlyNumbers: {
    id: 'sambla.no.employedToUseOnlyNumbers',
    defaultMessage: 'Enter when your project employment ends, only digits (i.e. {nextYear}).',
    description: 'Error message informing the applicant that they need to fill in when their employment ends, only in numbers',
  },
  employedToFormat: {
    id: 'sambla.no.employedToFormat',
    defaultMessage: 'Enter the year with four digits (i.e. {nextYear})',
    description: 'Error message reminding the customer that the year needs to be in the correct format (xxxx)',
  },
  employedToDateIsInThePast: {
    id: 'sambla.no.employedToDateIsInThePast',
    defaultMessage: 'The end date must be a future date.',
    description: 'Error message informing the applicant that the choosen date was set to a date in the past',
  },
  employedToDateTooFarInTheFuture: {
    id: 'sambla.no.employedToDateTooFarInTheFuture',
    defaultMessage: 'The end date is too far in the future',
    description: 'Error message informing the applicant that the choosen date that is more than 100 years in the future',
  },
  employedSinceMonthRequired: {
    id: 'sambla.no.employedSinceMonthRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed since"-month must be selected',
  },
  requiredDependants: {
    id: 'sambla.no.requiredDependants',
    defaultMessage: 'Please enter number of children.',
    description: 'Error message informing the applicant that the number of dependants/kids havent been filled in',
  },
  dependantsUseOnlyNumbers: {
    id: 'sambla.no.dependantsUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that they may only use numbers when filling in the number of dependants/kids',
  },
  tooLowDependants: {
    id: 'sambla.no.tooLowDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too low of an amount of dependants',
  },
  tooHighDependants: {
    id: 'sambla.no.tooHighDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too high of an amount of dependants',
  },
  consolidateLoanUseOnlyNumber: {
    id: 'sambla.no.consolidateLoanUseOnlyNumber',
    defaultMessage: 'Use numbers please.',
    description: 'Asking the customer/applicant to only use numbers for the input',
  },
  tooHighConsolidateLoan: {
    id: 'sambla.no.tooHighConsolidateLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have entered a loan amount to high. Also shows the max allowed amount',
  },
  requiredCivilStatus: {
    id: 'sambla.no.requiredCivilStatus',
    defaultMessage: 'Please enter a martial status.',
    description: 'Error message informing the applicant that their civil state must be provided',
  },
  phoneNumberRequired: {
    id: 'sambla.no.phoneNumberRequired',
    defaultMessage: 'Please enter your phone number',
    description: '',
  },
  phoneNumberMobilePrefixError: {
    id: 'sambla.no.phoneNumberMobilePrefixError',
    defaultMessage: 'Your phone number must begin with 07, +46 or 0046.',
    description: '',
  },
  wrongPhoneNumberNumber: {
    id: 'sambla.no.wrongPhoneNumberNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  wrongPhoneNumberBeginning: {
    id: 'sambla.no.wrongPhoneNumberBeginning',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  requiredNationalId: {
    id: 'sambla.no.requiredNationalId',
    defaultMessage: 'A national ID is required.',
    description: 'Error message informing the applicant that a social security number/national ID must be provided',
  },
  wrongNationalIdFormat: {
    id: 'sambla.no.wrongNationalIdFormat',
    defaultMessage: 'Wrong format, for example: 131159-2950.',
    description: 'Error message informing the applicant that the social security number/national ID is wrongly formatted',
  },
  tooYoungNationalId: {
    id: 'sambla.no.tooYoungNationalId',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to low required to make an application',
  },
  tooOldNationalId: {
    id: 'sambla.no.tooOldNationalId',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to high required to make an application',
  },
  invalidNationalId: {
    id: 'sambla.no.invalidNationalId',
    defaultMessage: 'Invalid national ID.',
    description: 'Error message informing the applicant that the social security number/national ID is invalid',
  },
  employerPhoneMissingPhoneNumber: {
    id: 'sambla.no.employerPhoneMissingPhoneNumber',
    defaultMessage: 'Please enter a phone number',
    description: '',
  },
  requiredBankAccount: {
    id: 'sambla.no.requiredBankAccount',
    defaultMessage: 'Bank account number is required.',
    description: 'Error message informing the applicant that a bank account number must be provided',
  },
  invalidBankAccountFormat: {
    id: 'sambla.no.invalidBankAccountFormat',
    defaultMessage: 'Wrong format.',
    description: 'Error message informing the applicant that the bank account number is wrongly formatted',
  },
  invalidBankAccount: {
    id: 'sambla.no.invalidBankAccount',
    defaultMessage: 'Invalid bank account number',
    description: 'Error message informing the applicant that the bank account number is invalid',
  },
  coApplicantEmployedToYearPlaceholder: {
    id: 'sambla.no.coApplicantEmployedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToYearLabel: {
    id: 'sambla.no.coApplicantEmployedToYearLabel',
    defaultMessage: 'Employed to (year)',
    description: 'Label for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToMonthLabel: {
    id: 'sambla.no.coApplicantEmployedToMonthLabel',
    defaultMessage: 'Employed to (month)',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToMonthPlaceholder: {
    id: 'sambla.no.coApplicantEmployedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToTooltip: {
    id: 'sambla.no.coApplicantEmployedToTooltip',
    defaultMessage: 'Enter when their employment ends.',
    description: 'Tooltip for coApplicant "employed until"-input, placed inside form',
  },
  employedToMonthLabel: {
    id: 'sambla.no.employedToMonthLabel',
    defaultMessage: 'Employed to (month)',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToYearPlaceholder: {
    id: 'sambla.no.employedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employed until"-input in years, placed inside form',
  },
  employedToYearLabel: {
    id: 'sambla.no.employedToYearLabel',
    defaultMessage: 'Employed to (year)',
    description: 'Label for "employed until"-input in years, placed inside form',
  },
  employedToMonthPlaceholder: {
    id: 'sambla.no.employedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToTooltip: {
    id: 'sambla.no.employedToTooltip',
    defaultMessage: 'Enter when your employment ends.',
    description: 'Tooltip for "employed until"-input, placed inside form',
  },
  retiredSinceDynamicLabel: {
    id: 'sambla.no.retiredSinceDynamicLabel',
    defaultMessage: 'Retired since',
    description: 'Dynamic label for employedSinceYear',
  },
  disabilityPensionSinceDynamicLabel: {
    id: 'sambla.no.disabilityPensionSinceDynamicLabel',
    defaultMessage: 'Disability pensioner since',
    description: 'Dynamic label for employedSinceYear',
  },
  employedSinceMonthplaceholder: {
    id: 'sambla.no.employedSinceMonthplaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed since"-input in months, placed inside form',
  },
  nextSlide: {
    id: 'sambla.no.nextSlide',
    defaultMessage: 'Next',
    description: 'general progress button label',
  },
  slideTitleOne: {
    id: 'sambla.no.slideTitleOne',
    defaultMessage: 'Your details',
    description: 'First slide title',
  },
  slideTitleTwo: {
    id: 'sambla.no.slideTitleTwo',
    defaultMessage: 'Employment',
    description: '',
  },
  slideTitleThree: {
    id: 'sambla.no.slideTitleThree',
    defaultMessage: 'Housing',
    description: '',
  },
  slideTitleFour: {
    id: 'sambla.no.slideTitleFour',
    defaultMessage: 'Current loans',
    description: '',
  },
  repaymentTimePlaceholder: {
    id: 'sambla.no.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  repaymentTimeLabel: {
    id: 'sambla.no.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  repaymentTimeSuffix: {
    id: 'sambla.no.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  repaymentTimeTooltip: {
    id: 'sambla.no.repaymentTimeTooltip',
    defaultMessage: 'For how long do you want to repay the loan? The longer the period, the lower your monthly cost will be.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  addCoApplicantLabel: {
    id: 'sambla.no.addCoApplicantLabel',
    defaultMessage: 'Do you want to increase your chances of receiving more favorable offers by adding a co-applicant?',
    description: 'Experiment-variant for add co-applicant option that the applicant can use if they wish to share their loan with a co-applicant',
  },
  addCoApplicantDesc: {
    id: 'sambla.no.addCoApplicantDesc',
    defaultMessage: 'Adding a co-applicant often gives you an increased chance of getting a loan, and often at a better interest rate than without a co-applicant!',
    description: 'Description in add co-applicant textbox',
  },
  addCoApplicantTooltip: {
    id: 'sambla.no.addCoApplicantTooltip',
    defaultMessage: 'Co-applicants provides the banks with better security as it is deemed a lower risk for the banks when there are two people applying for the loan. This in hand often gives more favorable terms and interest rates for the applicant(s), depending on your economical situation."',
    description: 'Tooltip for adding co-applicant',
  },
  purposeHeaderText: {
    id: 'sambla.no.purposeHeaderText',
    defaultMessage: 'Loan purpose',
    description: 'Header in the application form for the group of loan purpose related inputs - The purpose of the loan',
  },
  purposeTooltip: {
    id: 'sambla.no.purposeTooltip',
    defaultMessage: 'If you have more than one purpose for the loan, state the main purpose. "Services" can be for, for example, weddings, parties or cleaning jobs.',
    description: 'Tooltip for The purpose of the loan',
  },
  civilStatusPlaceholder: {
    id: 'sambla.no.civilStatusPlaceholder',
    defaultMessage: 'Please enter marital status',
    description: 'Default-text för civilstånd i formulär.',
  },
  civilStatusLabel: {
    id: 'sambla.no.civilStatusLabel',
    defaultMessage: 'Marital status',
    description: 'Label-text för civilstånd i formulär.',
  },
  civilStatusTooltip: {
    id: 'sambla.no.civilStatusTooltip',
    defaultMessage: 'Please enter your relationship status. If you are living separatly, use the option "Single',
    description: 'Tooltip-text civilstånd i formulär.',
  },
  civilStatusSingle: {
    id: 'sambla.no.civilStatusSingle',
    defaultMessage: 'Single',
    description: 'Marital status of the applicant - single',
  },
  civilStatusPartner: {
    id: 'sambla.no.civilStatusPartner',
    defaultMessage: 'Cohabitant',
    description: 'Marital status of the applicant - partner',
  },
  civilStatusMarried: {
    id: 'sambla.no.civilStatusMarried',
    defaultMessage: 'Married',
    description: 'Marital status of the applicant - married',
  },
  coApplicantCivilStatusLabel: {
    id: 'sambla.no.coApplicantCivilStatusLabel',
    defaultMessage: 'Marital status of co-applicant',
    description: 'Label for civil status of co-applicant',
  },
  coApplicantCivilStatusTooltip: {
    id: 'sambla.no.coApplicantCivilStatusTooltip',
    defaultMessage: 'Please enter the relationship status of your co-applicant. If he/she is living separatly to their partner, use option "Single".',
    description: 'Tooltip-text for coApplicant civil status in form.',
  },
  applicationLoanAmountPlaceholder: {
    id: 'sambla.no.applicationLoanAmountPlaceholder',
    defaultMessage: 'XX XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountLabel: {
    id: 'sambla.no.applicationLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountSuffix: {
    id: 'sambla.no.applicationLoanAmountSuffix',
    defaultMessage: 'NOK',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountTooltip: {
    id: 'sambla.no.applicationLoanAmountTooltip',
    defaultMessage: 'Enter the amount for this loan.',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  currentLoanMergeLabel: {
    id: 'sambla.no.currentLoanMergeLabel',
    defaultMessage: 'I want to consolidate loans',
    description: 'Label-text för val av att lösa lån i formulär.',
  },
  currentLoanMergeTooltip: {
    id: 'sambla.no.currentLoanMergeTooltip',
    defaultMessage: 'Our partners can give you a more favorable interest rate if you chose to consolidate your loans. This way, they "buy out" parts or your whole loan situation and therefore merging them into a single loan',
    description: 'Tooltip-text för val av att lösa lån i formulär.',
  },
  dependantsLabel: {
    id: 'sambla.no.dependantsLabel',
    defaultMessage: 'Number of children under 18',
    description: 'Label-text för antal barn i formulär.',
  },
  dependantsTooltip: {
    id: 'sambla.no.dependantsTooltip',
    defaultMessage: 'Please enter the number of children living at home who are under 18 years old',
    description: 'Tooltip-text för antal barn i formulär.',
  },
  dependantsZero: {
    id: 'sambla.no.dependantsZero',
    defaultMessage: '0',
    description: 'No child',
  },
  dependantsOne: {
    id: 'sambla.no.dependantsOne',
    defaultMessage: '1',
    description: 'One child',
  },
  dependantsTwo: {
    id: 'sambla.no.dependantsTwo',
    defaultMessage: '2',
    description: 'Two children',
  },
  dependantsThree: {
    id: 'sambla.no.dependantsThree',
    defaultMessage: '3',
    description: 'Three children',
  },
  dependantsFour: {
    id: 'sambla.no.dependantsFour',
    defaultMessage: '4',
    description: 'Four children',
  },
  dependantsFive: {
    id: 'sambla.no.dependantsFive',
    defaultMessage: '5+',
    description: 'Five children',
  },
  coApplicantDependantsLabel: {
    id: 'sambla.no.coApplicantDependantsLabel',
    defaultMessage: 'Number of children under 18',
    description: 'Label text for number of children',
  },
  coApplicantDependantsTooltip: {
    id: 'sambla.no.coApplicantDependantsTooltip',
    defaultMessage: "Please enter the number of childrens living at your co-applicant's home who are under 18 years old",
    description: 'Tooltip-text for coApplicant dependants in form.',
  },
  emailPlaceholder: {
    id: 'sambla.no.emailPlaceholder',
    defaultMessage: 'Your email',
    description: 'Default-text för e-post i formulär.',
  },
  emailLabel: {
    id: 'sambla.no.emailLabel',
    defaultMessage: 'Email',
    description: 'Label-text för e-post i formulär.',
  },
  emailTooltip: {
    id: 'sambla.no.emailTooltip',
    defaultMessage: 'Your e-mail is required so we can contact and send out information regarding your application to you. If a bid is accepted, the banks will also communicate with you using this e-mail.',
    description: 'Tooltip-text för e-post i formulär.',
  },
  emailSuggestion: {
    id: 'sambla.no.emailSuggestion',
    defaultMessage: 'Did you mean',
    description: 'The pre-suggestion helptext at the email input field.',
  },
  emailMustBeUnique: {
    id: 'sambla.no.emailMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same email.',
    description: 'Informing the customer that the applicant and co-applicant can not use the same e-mail',
  },
  coApplicantEmailLabel: {
    id: 'sambla.no.coApplicantEmailLabel',
    defaultMessage: 'E-mail of co-applicant',
    description: 'Label-text för e-post i formulär.',
  },
  coApplicantEmailPlaceholder: {
    id: 'sambla.no.coApplicantEmailPlaceholder',
    defaultMessage: 'Co-applicant’s email',
    description: 'Default-text for coApplicant e-mail in form.',
  },
  coApplicantEmailTooltip: {
    id: 'sambla.no.coApplicantEmailTooltip',
    defaultMessage: "Your co-applicant's e-mail is needed for them to be able to take part of the loan offers",
    description: 'Tooltip-text for coApplicant e-mail in form.',
  },
  emailRequired: {
    id: 'sambla.no.emailRequired',
    defaultMessage: 'Please enter your e-mail',
    description: 'Error message informing the applicant that an email address must be provided',
  },
  emailNoSpaces: {
    id: 'sambla.no.emailNoSpaces',
    defaultMessage: 'The email cannot contain blank spaces.',
    description: 'Error message informing the applicant that the provided email address must not contain any spaces',
  },
  emailNoAt: {
    id: 'sambla.no.emailNoAt',
    defaultMessage: 'A valid e-mail needs to include @',
    description: 'Error message informing the applicant that the provided email address must contain the symbol "@"',
  },
  emailTooManyAts: {
    id: 'sambla.no.emailTooManyAts',
    defaultMessage: 'The email cannot contain more than one @.',
    description: 'Error message informing the applicant that the provided email address may not contain more than one "@"',
  },
  emailNoName: {
    id: 'sambla.no.emailNoName',
    defaultMessage: 'A valid e-mail needs something before the @',
    description: 'Error message informing the applicant that the email address must contain a name in from of the email address',
  },
  emailDotBeforeAtSign: {
    id: 'sambla.no.emailDotBeforeAtSign',
    defaultMessage: 'The e-mail domain cannot contain a dot (.) before @.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailTwoDots: {
    id: 'sambla.no.emailTwoDots',
    defaultMessage: 'The email cannot contain two dots in a row.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailNoDomain: {
    id: 'sambla.no.emailNoDomain',
    defaultMessage: 'A valid e-mail needs to end with .se/.com/.net or alike',
    description: 'Error message informing the applicant that the email address provided must end with a domain, for example ".com", ".se" or ".nu"',
  },
  emailNoTopLevelDomain: {
    id: 'sambla.no.emailNoTopLevelDomain',
    defaultMessage: 'A valid e-mail needs a web address after @',
    description: 'Error message informing the applicant that the email address provided must contain a domain name after the "@"',
  },
  coApplicantEmailRequired: {
    id: 'sambla.no.coApplicantEmailRequired',
    defaultMessage: "Please enter your co-applicant's e-mail",
    description: 'Error message informing the applicant that an email address must be provided',
  },
  employerNamePlaceholder: {
    id: 'sambla.no.employerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default-text för arbetsgivarnamn i formulär.',
  },
  employerNameLabel: {
    id: 'sambla.no.employerNameLabel',
    defaultMessage: 'Employer',
    description: 'Label-text för företagsnamn i formulär.',
  },
  employerNameTooltip: {
    id: 'sambla.no.employerNameTooltip',
    defaultMessage: 'Please enter the name of the workplace you currently work at. If you have several employers, write the one that you would consider is your main one. Our partners need this information to handle your application.',
    description: 'Tooltip-text för företagsnamn i formulär.',
  },
  employerNameRequired: {
    id: 'sambla.no.employerNameRequired',
    defaultMessage: 'Please enter the name of your employer',
    description: 'Error message informing the applicant that an employer must be provided',
  },
  coApplicantEmployerNamePlaceholder: {
    id: 'sambla.no.coApplicantEmployerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default text employer name',
  },
  coApplicantEmployerNameLabel: {
    id: 'sambla.no.coApplicantEmployerNameLabel',
    defaultMessage: 'Employer of co-applicant',
    description: 'Label text for employer name',
  },
  coApplicantEmployerNameTooltip: {
    id: 'sambla.no.coApplicantEmployerNameTooltip',
    defaultMessage: "Please enter the name of the workplace your co-applicant's currently work at. If they have several employers, write the one that would be considered to be the main one.",
    description: 'Tooltip-text for coApplicant company name in form.',
  },
  coApplicantEmployerNameRequired: {
    id: 'sambla.no.coApplicantEmployerNameRequired',
    defaultMessage: "Please enter the name of your co-applicant's employer",
    description: 'Error message informing the applicant that an employer must be provided',
  },
  employerPhonePlaceholder: {
    id: 'sambla.no.employerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'Default-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneLabel: {
    id: 'sambla.no.employerPhoneLabel',
    defaultMessage: 'Employer phone number',
    description: 'Label-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneTooltip: {
    id: 'sambla.no.employerPhoneTooltip',
    defaultMessage: 'Your employer telephone number can make it easier for our partners if they need additional information when considering giving out a loan proposal',
    description: 'Tooltip-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneRequired: {
    id: 'sambla.no.employerPhoneRequired',
    defaultMessage: "Please enter your employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their employer',
  },
  coApplicantEmployerPhoneRequired: {
    id: 'sambla.no.coApplicantEmployerPhoneRequired',
    defaultMessage: "Please enter their employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their co applicants employer',
  },
  employerPhoneWrongNumber: {
    id: 'sambla.no.employerPhoneWrongNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid number',
    description: 'Error message for when the customer fills in a non-valid input for the number to their employer.',
  },
  employerPhoneWrongBeginning: {
    id: 'sambla.no.employerPhoneWrongBeginning',
    defaultMessage: 'The number needs to start with 0, +46 or 0046.',
    description: 'Error message for when the customer fills in a foreign phone number to their employer.',
  },
  employerPhoneTooShort: {
    id: 'sambla.no.employerPhoneTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten a digit",
    description: 'Error message for when the customer fills in a too short phone number to their employer.',
  },
  employerPhoneTooLong: {
    id: 'sambla.no.employerPhoneTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number to their employer.',
  },
  coApplicantEmployerPhonePlaceholder: {
    id: 'sambla.no.coApplicantEmployerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'placeholder text for coApplicant employer phone number in form.',
  },
  coApplicantEmployerPhoneLabel: {
    id: 'sambla.no.coApplicantEmployerPhoneLabel',
    defaultMessage: 'Employer phone of co-applicant',
    description: 'label text for coApplicant employer phone number in form.',
  },
  coApplicantEmployerPhoneTooltip: {
    id: 'sambla.no.coApplicantEmployerPhoneTooltip',
    defaultMessage: "Your co-applicant's employer telephone number can make it easier for our partners if they need additional information when considering giving out a loan proposal.",
    description: 'Tooltip-text for coApplicant employer phone number in form.',
  },
  employmentTypeLabel: {
    id: 'sambla.no.employmentTypeLabel',
    defaultMessage: 'Employment',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  employmentTypeTooltip: {
    id: 'sambla.no.employmentTypeTooltip',
    defaultMessage: 'Please select your employment type. If you are on leave, choose the employment option from which you got your leave. If you have several employments, please select the one that you consider is your main one. The choice "Pensioner" also includes early retirees.',
    description: 'Tooltip-text för sysselsättnig i formulär.',
  },
  employmentTypePermanent: {
    id: 'sambla.no.employmentTypePermanent',
    defaultMessage: 'Permanent',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice',
  },
  employmentTypePermanentPrivate: {
    id: 'sambla.no.employmentTypePermanentPrivate',
    defaultMessage: 'Permanent private sector',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice - private sector',
  },
  employmentTypePermanentPublic: {
    id: 'sambla.no.employmentTypePermanentPublic',
    defaultMessage: 'Permanent public sector',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice - public sector',
  },
  employmentTypeTemporary: {
    id: 'sambla.no.employmentTypeTemporary',
    defaultMessage: 'Temporary employment',
    description: 'Employment type - Temporary empolyment',
  },
  employmentTypeSelfEmployed: {
    id: 'sambla.no.employmentTypeSelfEmployed',
    defaultMessage: 'Self employed',
    description: 'Employment type - self employed',
  },
  employmentTypeRetired: {
    id: 'sambla.no.employmentTypeRetired',
    defaultMessage: 'Pensioner',
    description: 'Employment type - Retired/early retirement',
  },
  employmentTypeUnemployed: {
    id: 'sambla.no.employmentTypeUnemployed',
    defaultMessage: 'Unemployed',
    description: 'Employment type - Unemployed',
  },
  employmentTypeDisabilityPension: {
    id: 'sambla.no.employmentTypeDisabilityPension',
    defaultMessage: 'Disability pension',
    description: 'Employment type - Retired/pensioner on disibility',
  },
  employmentTypeProject: {
    id: 'sambla.no.employmentTypeProject',
    defaultMessage: 'Fixed-term',
    description: 'Employment type - Temporary employment/employed during project',
  },
  employmentTypeRehabilitation: {
    id: 'sambla.no.employmentTypeRehabilitation',
    defaultMessage: 'Rehabilitation',
    description: 'Employment type - Rehabilitation',
  },
  employmentTypeByTheHour: {
    id: 'sambla.no.employmentTypeByTheHour',
    defaultMessage: 'Hourly wage',
    description: 'Employment type - Extra work/hourly contract',
  },
  employmentTypeEmployedAtOwnCompany: {
    id: 'sambla.no.employmentTypeEmployedAtOwnCompany',
    defaultMessage: 'Own business',
    description: 'Employment type - Has his/her own business',
  },
  coApplicantEmploymentTypeLabel: {
    id: 'sambla.no.coApplicantEmploymentTypeLabel',
    defaultMessage: 'Employment of co-applicant',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  coApplicantEmploymentTypeTooltip: {
    id: 'sambla.no.coApplicantEmploymentTypeTooltip',
    defaultMessage: "Please select your co-applicant's employment type. If they are on leave, choose the employment option from which they got their leave. If they have several employments, please select the one that is considered the main one. The choice 'Pensioner' also includes early retirees.",
    description: 'Tooltip-text for coApplicant employment in form.',
  },
  employmentSinceMonthTooltip: {
    id: 'sambla.no.employmentSinceMonthTooltip',
    defaultMessage: 'Enter approximately when you were employed by the company you work for today.',
    description: 'Tooltip for "employment since"-input',
  },
  employmentSinceMonthPlaceholder: {
    id: 'sambla.no.employmentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  employmentSinceYearPlaceholder: {
    id: 'sambla.no.employmentSinceYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  employmentSinceYearLabel: {
    id: 'sambla.no.employmentSinceYearLabel',
    defaultMessage: 'Employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  employmentSinceMonthLabel: {
    id: 'sambla.no.employmentSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  employmentSinceYearTooltip: {
    id: 'sambla.no.employmentSinceYearTooltip',
    defaultMessage: 'Enter approximately how long you have been employed by the company you work for today.',
    description: 'Tooltip for "employment since"-input',
  },
  employedSinceMonthPlaceholder: {
    id: 'sambla.no.employedSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  retiredEmploymentSinceLabel: {
    id: 'sambla.no.retiredEmploymentSinceLabel',
    defaultMessage: 'Retired since',
    description: '',
  },
  retiredEmploymentSinceTooltip: {
    id: 'sambla.no.retiredEmploymentSinceTooltip',
    defaultMessage: 'Enter approximately when you retired.',
    description: '',
  },
  coApplicantRetiredEmploymentSinceTooltip: {
    id: 'sambla.no.coApplicantRetiredEmploymentSinceTooltip',
    defaultMessage: 'Enter approximately when your co-applicant retired.',
    description: 'Tooltip-text for coApplicant retired since in form.',
  },
  employedSinceRequiredYear: {
    id: 'sambla.no.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year you started your current employment',
    description: 'Error message informing the applicant that they must pass in which year their employment started',
  },
  coAppEmployedSinceRequiredYear: {
    id: 'sambla.no.coApplicant.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year your co-applicant started at their current employer',
    description: 'Error message informing the co applicant that they must pass in which year their employment started',
  },
  employedSinceRequiredMonth: {
    id: 'sambla.no.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month you started your current employment',
    description: 'Error message informing the applicant that they must pass in which month their employment started',
  },
  coAppEmployedSinceRequiredMonth: {
    id: 'sambla.no.coApplicant.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month your co-applicant started at their current employer',
    description: 'Error message informing the co applicant that they must pass in which month their employment started',
  },
  employedSinceOnlyNumbers: {
    id: 'sambla.no.employedSinceOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year their employment started',
  },
  employedSinceFourDigits: {
    id: 'sambla.no.employedSinceFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year their employment started" should be filled in with four digits',
  },
  employedSinceTooFarBack: {
    id: 'sambla.no.employedSinceTooFarBack',
    defaultMessage: 'Enter the year you started (e.g. 2010).',
    description: 'Error message informing the applicant that the year their employment started is set to far back in time',
  },
  coApplicantEmployedSinceTooFarBack: {
    id: 'sambla.no.coApplicant.employedSinceTooFarBack',
    defaultMessage: 'Enter the year they started (e.g. 2010).',
    description: 'Error message informing the co applicant started their employment to far back in time',
  },
  employedSinceStartTimeInTheFuture: {
    id: 'sambla.no.employedSinceStartTimeInTheFuture',
    defaultMessage: 'Your employment date cannot be in the future',
    description: 'Error message informing the applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  coAppEmployedSinceStartTimeInTheFuture: {
    id: 'sambla.no.employedSinceCoApplicant.startTimeInTheFuture',
    defaultMessage: "Their employment date can't be in the future.",
    description: 'Error message informing the co applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  employedSinceStartTimeInTheFutureMonth: {
    id: 'sambla.no.employedSinceStartTimeInTheFutureMonth',
    defaultMessage: "The employment date can't be in the future",
    description: 'Error message informing the start date is in the future',
  },
  homeCostPlaceholder: {
    id: 'sambla.no.homeCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Default-text för boendekostnad i formulär.',
  },
  homeCostLabel: {
    id: 'sambla.no.homeCostLabel',
    defaultMessage: 'Housing cost (NOK/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  homeCostTooltip: {
    id: 'sambla.no.homeCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If you are several who split the cost, enter your share in (e.g. half the total cost).',
    description: 'Tooltip-text för boendekostnad i formulär.',
  },
  homeCostRequired: {
    id: 'sambla.no.homeCostRequired',
    defaultMessage: 'Please enter your monthly housing cost',
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  homeCostOnlyDigits: {
    id: 'sambla.no.homeCostOnlyDigits',
    defaultMessage: 'Please write your housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  homeCostTooHigh: {
    id: 'sambla.no.homeCostTooHigh',
    defaultMessage: 'Your housing cost cannot be more than {maxCost, number} NOK/mth for you to apply',
    description: 'Inform the customer that we may unfortunatly only accept a monthly cost of "x" and that their cost is to high',
  },
  homeCostTooLow: {
    id: 'sambla.no.homeCostTooLow',
    defaultMessage: 'Enter monthly cost for your accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  coApplicantHomeCostPlaceholder: {
    id: 'sambla.no.coApplicantHomeCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Default-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostLabel: {
    id: 'sambla.no.coApplicantHomeCostLabel',
    defaultMessage: 'Housing cost (NOK/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostTooltip: {
    id: 'sambla.no.coApplicantHomeCostTooltip',
    defaultMessage: "Please enter the monthly cost connected to your co-applicant's housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If there are several who split the cost, enter your co-applicant's share in (e.g. half the total cost).",
    description: 'Tooltip-text for coApplicant home cost in form.',
  },
  coApplicantHomeCostRequired: {
    id: 'sambla.no.coApplicantHomeCostRequired',
    defaultMessage: "Please enter your co-applicant's monthly housing cost",
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  coApplicantHomeCostOnlyDigits: {
    id: 'sambla.no.coApplicantHomeCostOnlyDigits',
    defaultMessage: 'Please write the housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  coApplicantHomeCostTooHigh: {
    id: 'sambla.no.coApplicantHomeCostTooHigh',
    defaultMessage: 'Their housing cost cannot be more than {maxCost, number} NOK/mth for you to apply',
    description: 'Inform the customer that we may unfortunatly only accept a monthly cost of "x" and that their cost is to high',
  },
  coApplicantHomeCostTooLow: {
    id: 'sambla.no.coApplicantHomeCostTooLow',
    defaultMessage: 'Enter monthly cost for their accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  monthlySalaryAfterTaxInvalid: {
    id: 'sambla.no.monthlySalaryAfterTaxInvalid',
    defaultMessage: 'Income after tax can not be higher than income before tax',
    description: 'Error message informing the customer that the monthly salary before tax is to high',
  },
  homeTypeLabel: {
    id: 'sambla.no.homeTypeLabel',
    defaultMessage: 'Housing',
    description: 'Label-text för boendeform i formulär.',
  },
  homeTypeTooltip: {
    id: 'sambla.no.homeTypeTooltip',
    defaultMessage: 'If you have several addresses, please choose the one where you live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text för boendeform i formulär.',
  },
  homeTypeRentingApartment: {
    id: 'sambla.no.homeTypeRentingApartment',
    defaultMessage: 'Rental',
    description: 'Residential type - Renting appartment',
  },
  homeTypeVilla: {
    id: 'sambla.no.homeTypeVilla',
    defaultMessage: 'Villa',
    description: 'Residential type - Villa',
  },
  homeTypeOwnApartment: {
    id: 'sambla.no.homeTypeOwnApartment',
    defaultMessage: 'Own apartment',
    description: 'Residential type - own apartment',
  },
  homeTypeParents: {
    id: 'sambla.no.homeTypeParents',
    defaultMessage: 'Parents',
    description: 'Residential type - Parents',
  },
  homeTypeHouseOrTownhouse: {
    id: 'sambla.no.homeTypeHouseOrTownhouse',
    defaultMessage: 'House',
    description: 'Residential type - House',
  },
  homeTypeCondominium: {
    id: 'sambla.no.homeTypeCondominium',
    defaultMessage: 'Condominium',
    description: 'Residential type - Condominium',
  },
  homeTypeRentingRoom: {
    id: 'sambla.no.homeTypeRentingRoom',
    defaultMessage: 'Live-in',
    description: 'Residential type - Renting room/lodger',
  },
  coApplicantHomeTypeLabel: {
    id: 'sambla.no.coApplicantHomeTypeLabel',
    defaultMessage: 'Housing of co-applicant',
    description: 'Label-text för boendeform i formulär.',
  },
  coApplicantHomeTypeTooltip: {
    id: 'sambla.no.coApplicantHomeTypeTooltip',
    defaultMessage: 'If your co-applicant have several addresses, please choose the one where they live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text for coApplicant home type in form.',
  },
  loanPurposeTooltip: {
    id: 'sambla.no.loanPurposeTooltip',
    defaultMessage: 'What are you mainly going to use the money for?',
    description: 'Tooltip-text för lånets syfte i formulär.',
  },
  loanPurposeHome: {
    id: 'sambla.no.loanPurposeHome',
    defaultMessage: 'Home',
    description: 'Loan purpose home',
  },
  loanPurposeTravel: {
    id: 'sambla.no.loanPurposeTravel',
    defaultMessage: 'Travel',
    description: 'Loan purpose travel',
  },
  loanPurposeBusiness: {
    id: 'sambla.no.loanPurposeBusiness',
    defaultMessage: 'Business',
    description: 'Loan purpose Business',
  },
  loanPurposeEverydayExpenses: {
    id: 'sambla.no.loanPurposeEverydayExpenses',
    defaultMessage: 'Everyday expenses',
    description: 'Loan purpose everyday expenses',
  },
  loanPurposeRequired: {
    id: 'sambla.no.loanPurposeRequired',
    defaultMessage: 'The loan purpose is required.',
    description: '',
  },
  loanPurposeConsolidateLoans: {
    id: 'sambla.no.loanPurposeConsolidateLoans',
    defaultMessage: 'Consolidate loans',
    description: 'Purpose of the loan - Consolidate loans',
  },
  loanPurposeOther: {
    id: 'sambla.no.loanPurposeOther',
    defaultMessage: 'Other',
    description: 'Purpose of the loan - Other',
  },
  loanPurposeVehicles: {
    id: 'sambla.no.loanPurposeVehicles',
    defaultMessage: 'Vehicle',
    description: 'Purpose of the loan - Vehichle expenses',
  },
  loanPurposeConsumption: {
    id: 'sambla.no.loanPurposeConsumption',
    defaultMessage: 'Consumption',
    description: 'Purpose of the loan - Shopping/consumption',
  },
  loanPurposeHealth: {
    id: 'sambla.no.loanPurposeHealth',
    defaultMessage: 'Health/medical',
    description: 'Purpose of the loan - Health',
  },
  loanPurposeRenovation: {
    id: 'sambla.no.loanPurposeRenovation',
    defaultMessage: 'Renovation',
    description: 'Purpose of the loan - Renovation',
  },
  loanPurposeServices: {
    id: 'sambla.no.loanPurposeServices',
    defaultMessage: 'Services',
    description: 'Purpose of the loan - Services(for example "wedding")',
  },
  loanPurposeBuffer: {
    id: 'sambla.no.loanPurposeBuffer',
    defaultMessage: 'Investment',
    description: 'Purpose of the loan - Buffer/Investment',
  },
  loanPurposeStudies: {
    id: 'sambla.no.loanPurposeStudies',
    defaultMessage: 'Studies',
    description: 'Purpose of the loan - Studies',
  },
  mergeLoanYes: {
    id: 'sambla.no.mergeLoanYes',
    defaultMessage: 'Yes',
    description: '',
  },
  mergeLoanNo: {
    id: 'sambla.no.mergeLoanNo',
    defaultMessage: 'No',
    description: '',
  },
  monthlySalaryTooltip: {
    id: 'sambla.no.monthlySalaryTooltip',
    defaultMessage: 'Enter your approximate total monthly income before taxes from all income sources. This can consist of salary, comission, rental income and pension, to name a few. Note that you need to prove your stated income through payment slips and alike.',
    description: 'Tooltip-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryPlaceholder: {
    id: 'sambla.no.coApplicantMonthlySalaryPlaceholder',
    defaultMessage: 'E.g. 25 000',
    description: 'Default-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryLabel: {
    id: 'sambla.no.coApplicantMonthlySalaryLabel',
    defaultMessage: 'Net income (NOK/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryTooltip: {
    id: 'sambla.no.coApplicantMonthlySalaryTooltip',
    defaultMessage: "Enter your co-applicant's approximate total monthly income before taxes. This can consist of salary, commission, rental income and pension, to name a few. Note that your co-applicant may need to prove their stated income through payment slips and alike.",
    description: 'Tooltip-text for coApplicant income in form.',
  },
  monthlySalaryRequired: {
    id: 'sambla.no.monthlySalaryRequired',
    defaultMessage: 'Please enter your monthly net income (NOK/mth)',
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  annualSalaryRequired: {
    id: 'sambla.no.annualSalaryRequired',
    defaultMessage: 'Please enter your annual income',
    description: 'Error message informing the applicant that a annual salary must be provided',
  },
  coApplicantAnnualSalaryRequired: {
    id: 'sambla.no.coApplicantAnnualSalaryRequired',
    defaultMessage: 'Please enter your co applicant\'s annual income',
    description: 'Error message informing the applicant that a annual salary must be provided',
  },
  rentalIncomeRequired: {
    id: 'sambla.no.rentalIncomeRequired',
    defaultMessage: 'Please enter your rental income',
    description: 'Error message informing the applicant that a rental income must be provided',
  },
  spouseAnnualSalaryRequired: {
    id: 'sambla.no.spouseAnnualSalaryRequired',
    defaultMessage: "Please enter your spouse's annual income",
    description: 'Error message informing the applicant that a annual salary must be provided',
  },
  monthlySalaryOnlynumbers: {
    id: 'sambla.no.monthlySalaryOnlynumbers',
    defaultMessage: 'Please write your income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  coApplicantMonthlySalaryOnlynumbers: {
    id: 'sambla.no.coApplicantMonthlySalaryOnlynumbers',
    defaultMessage: 'Please write your co applicant\'s income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  spouseMonthlySalaryOnlynumbers: {
    id: 'sambla.no.spouseMonthlySalaryOnlynumbers',
    defaultMessage: "Please write your spouse's income in only numbers",
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  monthlySalaryTooLow: {
    id: 'sambla.no.monthlySalaryTooLow',
    defaultMessage: 'Your net income must be at least {minSalary, number} NOK/mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  annualSalaryTooLow: {
    id: 'sambla.no.annualSalaryTooLow',
    defaultMessage: 'The annual income must be at least {minAnnualSalary, number} NOK in order to apply',
    description: 'Error message informing the customer that the annual salary provided is to low and information that you need to have a annual salary of at least x in order to compare loans',
  },
  monthlySalaryTooHigh: {
    id: 'sambla.no.monthlySalaryTooHigh',
    defaultMessage: 'Your net income must not be more than {maxSalary, number} NOK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  rentalIncomeTooHigh: {
    id: 'sambla.no.rentalIncomeTooHigh',
    defaultMessage: 'Your rental income must not be more than {maxRentalIncome, number} NOK/mth for you to apply',
    description: 'Error message informing the customer that the rental income provided is to high',
  },
  coApplicantMonthlySalaryTooHgih: {
    id: 'sambla.no.coApplicantMonthlySalaryTooHgih',
    defaultMessage: 'Your co-applicant net income must not be more than {maxSalary, number} NOK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  annualSalaryTooHigh: {
    id: 'sambla.no.annualSalaryTooHigh',
    defaultMessage: 'The annual income must not be more than {maxAnnualSalary, number} NOK',
    description: 'Error message informing the customer that the annual salary provided is to high',
  },
  coApplicantMonthlySalaryRequired: {
    id: 'sambla.no.coApplicantMonthlySalaryRequired',
    defaultMessage: "Please enter your co-applicant's monthly net income (NOK/mth)",
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  monthlySalaryAfterTaxRequired: {
    id: 'sambla.no.monthlySalaryAfterTaxRequired',
    defaultMessage: 'Please enter your monthly net income (NOK/mth)',
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  coApplicantMonthlySalaryTooLow: {
    id: 'sambla.no.coApplicantMonthlySalaryTooLow',
    defaultMessage: 'Their net income must be at least {minSalary, number} NOK/mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  coApplicantMonthlySalaryTooHigh: {
    id: 'sambla.no.coApplicantMonthlySalaryTooHigh',
    defaultMessage: 'Their net income must not be more than {maxSalary, number} NOK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  nationalIdPlaceholder: {
    id: 'sambla.no.nationalIdPlaceholder',
    defaultMessage: 'DDMMYYXXXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  nationalIdLabel: {
    id: 'sambla.no.nationalIdLabel',
    defaultMessage: 'National ID',
    description: 'Label-text för personnummer i formulär.',
  },
  nationalIdTooltip: {
    id: 'sambla.no.nationalIdTooltip',
    defaultMessage: 'The application is personal and your swedish national ID is needed to verify your identity. The information is secure with us and processed securely according to GDPR.',
    description: 'Tooltip-text för personnummer i formulär.',
  },
  nationalIdMustBeUnique: {
    id: 'sambla.no.nationalIdMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot be the same person.',
    description: 'Informing the customer that the applicant and co-applicant can not be the same person/use the same social security number',
  },
  nationalIdRequired: {
    id: 'sambla.no.nationalIdRequired',
    defaultMessage: 'Please enter your Swedish ID number',
    description: 'Error message for when a social security number must be provided',
  },
  nationalIdWrongFormat: {
    id: 'sambla.no.nationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 801122-0123',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct formated social security number',
  },
  nationalIdWrongDate: {
    id: 'sambla.no.nationalIdWrongDate',
    defaultMessage: 'Your birth date {dateOfBirthString} is not valid. Please make sure there are no mistakes',
    description: 'Error message when providing birth date incorrectly',
  },
  nationalIdDateInTheFuture: {
    id: 'sambla.no.nationalIdDateInTheFuture',
    defaultMessage: "{dateOfBirthString} is a future date, and can't be used",
    description: 'Error message when choosing date of birth as a date in the future',
  },
  nationalIdTooYoung: {
    id: 'sambla.no.nationalIdTooYoung',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message when providing a date of birth making you younger than the minimum age allowed',
  },
  nationalIdTooOld: {
    id: 'sambla.no.nationalIdTooOld',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message when providing a date of birth making you older than the maximum age allowed',
  },
  nationalIdInvalid: {
    id: 'sambla.no.nationalIdInvalid',
    defaultMessage: 'Your 4 last digits do not seem to match your birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formated. Asks the customer to review their last four digits in their social security number',
  },
  coApplicantNationalIdPlaceholder: {
    id: 'sambla.no.coApplicantNationalIdPlaceholder',
    defaultMessage: 'YYMMDDXXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  coApplicantNationalIdLabel: {
    id: 'sambla.no.coApplicantNationalIdLabel',
    defaultMessage: 'National ID of co-applicant',
    description: 'Label-text för personnummer i formulär.',
  },
  coApplicantNationalIdTooltip: {
    id: 'sambla.no.coApplicantNationalIdTooltip',
    defaultMessage: 'Since the application is personal, we need to verify your co-applicant national ID (dont write your own). This needs to be a swedish social security number',
    description: 'Tooltip-text for coApplicant in form.',
  },
  coApplicantNationalIdrequired: {
    id: 'sambla.no.coApplicantNationalIdrequired',
    defaultMessage: "Please enter your co-applicant's Swedish ID number",
    description: 'Error message for when a social security number must be provided',
  },
  coApplicantNationalIdWrongFormat: {
    id: 'sambla.no.coApplicantNationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 801122-0123',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct formated social security number',
  },
  coApplicantNationalIdWrongDate: {
    id: 'sambla.no.coApplicantNationalIdWrongDate',
    defaultMessage: 'Their birth date {dateOfBirthString} is not valid, please make sure your input is correct',
    description: 'Error message when providing birth date incorrectly',
  },
  coApplicantNationalIdDateInTheFuture: {
    id: 'sambla.no.coApplicantNationalIdDateInTheFuture',
    defaultMessage: "{dateOfBirthString} is a future date, and can't be used",
    description: 'Error message when choosing date of birth as a date in the future',
  },
  coApplicantNationalIdInvalid: {
    id: 'sambla.no.coApplicantNationalIdInvalid',
    defaultMessage: 'Their 4 last digits seems to not match the birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formated. Asks the customer to review their last four digits in their social security number',
  },
  phoneNumberPlaceholder: {
    id: 'sambla.no.phoneNumberPlaceholder',
    defaultMessage: 'XXXX __ __ __',
    description: 'Default-text för mobilnummer i formulär.',
  },
  phoneNumberLabel: {
    id: 'sambla.no.phoneNumberLabel',
    defaultMessage: 'Phone number',
    description: '',
  },
  phoneNumberTooltip: {
    id: 'sambla.no.phoneNumberTooltip',
    defaultMessage: 'Your telephone number is required so we can give you information about your application. If a bid is accepted, the banks will also communicate with you using this telephone number.',
    description: 'Tooltip-text för mobilnummer i formulär.',
  },
  phoneNumberMustBeUnique: {
    id: 'sambla.no.phoneNumberMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same phone number.',
    description: 'Informing the customer/applicant that their co-applicant may not have an identical phone number as theirs',
  },
  phoneNumberWrongBeginning: {
    id: 'sambla.no.phoneNumberWrongBeginning',
    defaultMessage: 'We only accept cell phone numbers. The number needs to start with +47 or 0047',
  },
  phoneNumberFormatError: {
    id: 'sambla.no.phoneNumberFormatError',
    defaultMessage: 'Must begin with 0 or Swedish country code (+46).',
    description: '',
  },
  phoneNumberPrefixError: {
    id: 'sambla.no.phoneNumberPrefixError',
    defaultMessage: 'Your phone number must begin with 04, 0457 or 050.',
    description: '',
  },
  phoneNumberWrongNumber: {
    id: 'sambla.no.phoneNumberWrongNumber',
    defaultMessage:
      'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  phoneNumberGeneralError: {
    id: 'sambla.no.phoneNumberGeneralError',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  phoneNumberTooShort: {
    id: 'sambla.no.phoneNumberTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten any digits",
    description: 'Error message for when the customer fills in a too short phone number.',
  },
  phoneNumberTooLong: {
    id: 'sambla.no.phoneNumberTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  coApplicantPhoneNumberTooltip: {
    id: 'sambla.no.coApplicantPhoneNumberTooltip',
    defaultMessage: "Your co-applicant's phone number is needed for them to be able to take part of the loan offers. When you have accepted a loan offer, banks might also need to come in contact with your co-applicant.",
    description: 'Tooltip-text for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberPlaceholder: {
    id: 'sambla.no.coApplicantPhoneNumberPlaceholder',
    defaultMessage: 'XXX XX XXX',
    description: 'Default-text för coApplicant mobilnummer i formulär.',
  },
  coApplicantPhoneNumberLabel: {
    id: 'sambla.no.coApplicantPhoneNumberLabel',
    defaultMessage: 'Phone number of co-applicant',
    description: 'label for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberRequired: {
    id: 'sambla.no.coApplicantPhoneNumberRequired',
    defaultMessage: "Please enter your co-applicant's phone number",
  },
  coApplicantEmploymentSinceYearPlaceholder: {
    id: 'sambla.no.coApplicantEmploymentSinceYearPlaceholder',
    defaultMessage: 'e.g 2002',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceYearLabel: {
    id: 'sambla.no.coApplicantEmploymentSinceYearLabel',
    defaultMessage: 'Employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceMonthLabel: {
    id: 'sambla.no.coApplicantEmploymentSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  coApplicantEmploymentSinceYearTooltip: {
    id: 'sambla.no.coApplicantEmploymentSinceYearTooltip',
    defaultMessage: 'Please enter approximately the year when your co-applicant started at their current company. Use 4 numbers, e.g "2002".',
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthTooltip: {
    id: 'sambla.no.coApplicantEmploymentSinceMonthTooltip',
    defaultMessage: "Please select approximately the month that your co-applicant started at their current company. If you don't know exactly, pick what you think it is.",
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthPlaceholder: {
    id: 'sambla.no.coApplicantEmploymentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  betterTermsText: {
    id: 'sambla.no.betterTermsText',
    defaultMessage: 'Having a co-applicant often results in better conditions.',
    description: '',
  },
  submitErrorGeneral: {
    id: 'sambla.no.submitErrorGeneral',
    defaultMessage: 'Your application could not be submitted. Customer service is more than happy to help you through the process. Call: <a>{phoneNumber}</a> or click the button below and we will call you as soon as we can on <i>{customerPhoneNumber}</i>',
    description: 'Default-text för back-end fel i formulär.',
  },
  submitErrorUncaught: {
    id: 'sambla.no.submitErrorUncaught',
    defaultMessage: 'For the moment we have technical issues and your application could not be submitted. Please contact customer service for more help',
    description: 'Default-text for when a customer tries to submit their application but there was an error on the client side.',
  },
  validationErrorGeneral: {
    id: 'sambla.no.validationErrorGeneral',
    defaultMessage: 'Some of the fields are not filled in correctly. Please correct these and try to submit again.',
    description: 'Default-text för fel i fält.',
  },
  validationErrorField: {
    id: 'sambla.no.validationErrorField',
    defaultMessage: 'This field is not filled in correctly. Please correct it and try submitting again.',
    description: 'Default-text för fel i fält.',
  },
  fieldAlreadyInUseEmail: {
    id: 'sambla.no.fieldAlreadyInUseEmail',
    defaultMessage: 'This email address already belongs to an application.',
    description: 'Field error message informing the customer that the given e-mail is already in use in another application',
  },
  fieldAlreadyInUseNationalId: {
    id: 'sambla.no.fieldAlreadyInUseNationalId',
    defaultMessage: 'You have an ongoing application, to access it — click "Log in" in the menu. To make changes to your application, please call customer service on: <span>{phoneNumber}</span>',
    description: 'Default-text för back-end fel i formulär.',
  },
  formSubmit: {
    id: 'sambla.no.formSubmit',
    defaultMessage: 'Apply now - for free',
    description: 'Label for submit button',
  },
  monthlyCostApproximateMonthlyCostLabel: {
    id: 'sambla.no.monthlyCostApproximateMonthlyCostLabel',
    defaultMessage: 'Monthly cost approx. *',
    description: 'The label for the apprax. monthly cost',
  },
  monthlyCostApproximateCostLabel: {
    id: 'sambla.no.monthlyCostApproximateCostLabel',
    defaultMessage: 'Approximate cost',
    description: 'approx cost',
  },
  monthlyCostApproximateCostPerMonthDesktop: {
    id: 'sambla.no.monthlyCostApproximateCostPerMonthDesktop',
    defaultMessage: '{monthlyCost} / month *',
    description: 'Shows the apprx. monthly cost on desktop',
  },
  monthlyCostApproximateCostPerMonthMobile: {
    id: 'sambla.no.monthlyCostApproximateCostPerMonthMobile',
    defaultMessage: '<strong>{monthlyCost}</strong>/mth*',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  monthlyCostApproximateCostLabelYears: {
    id: 'sambla.no.monthlyCostApproximateCostLabelYears',
    defaultMessage: 'in <strong> {repaymentTime} years </strong>',
    description: 'Repayment time in years',
  },
  yearlyCostApproximateCostLabelYears: {
    id: 'sambla.no.yearlyCostApproximateCostLabelYears',
    defaultMessage: '<strong> {repaymentTime} years </strong>',
    description: 'Repayment time in years',
  },
  yearlyCostApproximateCostPerMonthMobile: {
    id: 'sambla.no.yearlyCostApproximateCostPerMonthMobile',
    defaultMessage: '<strong> {monthlyCost}</strong>/mth*',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  combinedLoanConsolidationAmount: {
    id: 'sambla.no.combinedLoanConsolidationAmount',
    defaultMessage: 'Loans to consolidate: <strong>{consolidationAmount}</strong>',
    description: 'Part of the loan that is consolidation amount',
  },
  samblaPlusText: {
    id: 'sambla.no.samblaPlusText',
    defaultMessage: 'I want to become a member of Sambla Plus to receive newsletters and offers via SMS and e-mail. ',
    description: 'Text besides sambla plus checkbox in form',
  },
  samblaPlusReadMore: {
    id: 'sambla.no.samblaPlusReadMore',
    defaultMessage: 'Read more',
    description: 'Text for link to read about Sambla Plus',
  },
  repaymentTimeWarning: {
    id: 'sambla.no.repaymentTimeWarning',
    defaultMessage: 'Note! A repayment period of more than 5 years or a loan amount over 600k requires you to refinance/collect loans and credits you already have.',
    description: 'warning when entering over 5 years repayment time',
  },
  loanCalculatorTotalLoanAmountLabel: {
    id: 'sambla.no.loanCalculator.totalLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountPlaceholder: {
    id: 'sambla.no.loanCalculator.totalLoanAmountPlaceholder',
    defaultMessage: 'XX XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountSuffix: {
    id: 'sambla.no.loanCalculator.totalLoanAmountSuffix',
    defaultMessage: 'NOK',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountTooltip: {
    id: 'sambla.no.loanCalculator.totalLoanAmountTooltip',
    defaultMessage: 'Please enter the total loan amount for the loan you want to use for calculation',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorInterestRateLabel: {
    id: 'sambla.no.loanCalculator.interestRateLabel',
    defaultMessage: 'Interest rate',
    description: 'Label for interest rate.',
  },
  loanCalculatorInterestRatePlaceholder: {
    id: 'sambla.no.loanCalculator.interestRatePlaceholder',
    defaultMessage: 'X.XX',
    description: 'Default text for interest rate.',
  },
  loanCalculatorInterestRateSuffix: {
    id: 'sambla.no.loanCalculator.interestRateSuffix',
    defaultMessage: '%',
    description: 'Suffix for interest rate.',
  },
  loanCalculatorInterestRateTooltip: {
    id: 'sambla.no.loanCalculator.interestRateTooltip',
    defaultMessage: 'The interest rate on loans can be seen as a monthly fee to the banks for lending you the money. Our partners offered interest rate is decided based on your financial situation and can vary. You can read more about effective interest rate <a>here.</a>',
    description: 'Tooltip for interest rate for the loan in this form.',
  },
  loanCalculatorRepaymentTimeLabel: {
    id: 'sambla.no.loanCalculator.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  loanCalculatorRepaymentTimePlaceholder: {
    id: 'sambla.no.loanCalculator.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  loanCalculatorRepaymentTimeSuffix: {
    id: 'sambla.no.loanCalculator.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  loanCalculatorRepaymentTimeTooltip: {
    id: 'sambla.no.loanCalculator.repaymentTimeTooltip',
    defaultMessage: 'The repayment time is how long your loan is planned to be repaid. Depending on the time, the monthly cost of your loan might change, where shorter repayment time often means a higher monthly cost.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  loanCalculatorSetUpFeeLabel: {
    id: 'sambla.no.loanCalculator.setUpFeeLabel',
    defaultMessage: 'Set-up Fee',
    description: 'Label text for start up fee.',
  },
  loanCalculatorSetUpFeePlaceholder: {
    id: 'sambla.no.loanCalculator.setUpFeePlaceholder',
    defaultMessage: 'NOK 300',
    description: 'Placeholder text for start up fee.',
  },
  loanCalculatorSetUpFeeTooltip: {
    id: 'sambla.no.loanCalculator.setUpFeeTooltip',
    defaultMessage: 'Some banks have a single set-up fee and/or recurring avia fees connected to your loan. You can usually find this info in the terms documentation of the loan or on the invoice.',
    description: 'Tooltip text for start up fee.',
  },
  loanCalculatorAviaFeeLabel: {
    id: 'sambla.no.loanCalculator.aviaFeeLabel',
    defaultMessage: 'Avia Fee',
    description: 'Label text for fee.',
  },
  loanCalculatorAviaFeePlaceholder: {
    id: 'sambla.no.loanCalculator.aviaFeePlaceholder',
    defaultMessage: 'NOK 25',
    description: 'Label text for avia fee.',
  },
  loanCalculatorAviaFeeTooltip: {
    id: 'sambla.no.loanCalculator.aviaFeeTooltip',
    defaultMessage: 'Some banks have a single set-up fee and/or recurring avia fees connected to your loan. You can usually find this info in the terms documentation of the loan or on the invoice.',
    description: 'Tooltip text for avia fee.',
  },
  loanCalculatorNextSlideButtonHeading: {
    id: 'sambla.no.loanCalculator.nextSlideButtonHeading',
    defaultMessage: 'Do you want to apply for a loan based on the calculation above?',
    description: 'Title above the teleporter',
  },
  loanCalculatorNextSlideButtonSubHeading: {
    id: 'sambla.no.loanCalculator.nextSlideButtonSubHeading',
    defaultMessage: 'Fill out the information on the next page',
    description: 'Subtitle above the teleporter',
  },
  loanCalculatorNextSlideButton: {
    id: 'sambla.no.loanCalculator.nextSlideButton',
    defaultMessage: 'To application',
    description: 'general progress button label',
  },
  loanCalculatorAmortizationTypeLabel: {
    id: 'sambla.no.loanCalculator.summaryPage.amortizationTypeLabel',
    defaultMessage: 'Type of amortization',
    description: 'Label for which type of amortization the user wants to calculate with',
  },
  loanCalculatorAmortizationTypeAnnuity: {
    id: 'sambla.no.loanCalculator.summaryPage.amortizationTypeAnnuity',
    defaultMessage: 'Annuity',
    description: 'Text for amortization type annuity',
  },
  loanCalculatorAmortizationTypeStraight: {
    id: 'sambla.no.loanCalculator.summaryPage.amortizationTypeStraight',
    defaultMessage: 'Straight',
    description: 'Text for amortization type straight',
  },
  loanCalculatorAmortizationTypeTooltip: {
    id: 'sambla.no.loanCalculator.summaryPage.amortizationTypeTooltip',
    defaultMessage: 'Amortization essentially means that you pay back a small portion of the loan every month. Samblas partners can provide either Annuity loans or Straight loans. Annuity loans are the most common and is where you pay the same monthly cost every month. You can read more about amortization <a>here.</a>',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsForLoan: {
    id: 'sambla.no.loanCalculator.summaryPage.totalCostsForLoan',
    defaultMessage: 'Total cost',
    description: 'Text for total costs',
  },
  loanCalculatorTotalCostsForLoanTooltip: {
    id: 'sambla.no.loanCalculator.summaryPage.totalCostsForLoanTooltip',
    defaultMessage: 'You need to pay back the whole sum of the loan through the amortization plan, but there are also fees and interest costs connected to the loan that are added and needs to be paid.',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoan: {
    id: 'sambla.no.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoan',
    defaultMessage: 'Interest and fees',
    description: 'Text for total costs and interest and fees for loan',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: {
    id: 'sambla.no.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoanTooltip',
    defaultMessage: 'These are the total fees and interest costs connected to the loan. This excludes the loan amount, and with it, the amortization.',
    description: 'Text for tooltip',
  },
  loanCalculatorMonthlyCost: {
    id: 'sambla.no.loanCalculator.summaryPage.monthlyCost',
    defaultMessage: 'Monthly cost:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostMonthOne: {
    id: 'sambla.no.loanCalculator.summaryPage.monthlyCostMonthOne',
    defaultMessage: 'Monthly cost, month 1:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostTooltip: {
    id: 'sambla.no.loanCalculator.summaryPage.monthlyCostTooltip',
    defaultMessage: 'This is the recurring monthly cost for you until you have paid back your loan. Depending on your amortization type, these costs might change with time.',
    description: 'Text for tooltip',
  },
  loanCalculatorMonthlyCostOverviewButtonText: {
    id: 'sambla.no.loanCalculator.summaryPage.monthlyCostOverviewButtonText',
    defaultMessage: 'See overview of monthly cost',
    description: 'Text for button',
  },

  loanCalculatorSummaryTablePageDescription: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTablePageDescription',
    defaultMessage: 'More info',
    description: 'Text for page description',
  },
  loanCalculatorEffectiveInterest: {
    id: 'sambla.no.loanCalculator.summaryPage.effectiveInterest',
    defaultMessage: 'Effective interest:',
    description: 'Text for summary table effective interest',
  },
  loanCalculatorSummaryTableColumnMonth: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTableColumnMonth',
    defaultMessage: 'Month',
    description: 'Text for summary table month column',
  },
  loanCalculatorSummaryTableColumnDebtBeforeAmortization: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTableColumnDebtBeforeAmortization',
    defaultMessage: 'Debt before amortization',
    description: 'Text for summary table debt before amortization column',
  },
  loanCalculatorSummaryTableColumnAmortization: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTableColumnAmortization',
    defaultMessage: 'Amortization',
    description: 'Text for summary table amortization column',
  },
  loanCalculatorSummaryTableColumnInterestCost: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTableColumnInterestCost',
    defaultMessage: 'Interest',
    description: 'Text for summary table interest cost column',
  },
  loanCalculatorSummaryTableColumnFeeCost: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTableColumnFeeCost',
    defaultMessage: 'Fees',
    description: 'Text for summary table fee cost column',
  },
  loanCalculatorSummaryTableColumnToPay: {
    id: 'sambla.no.loanCalculator.summaryPage.summaryTableColumnToPay',
    defaultMessage: 'To pay',
    description: 'Text for summary table to pay column',
  },
  loanCalculatorCurrencySuffix: {
    id: 'sambla.no.loanCalculator.summaryPage.currencySuffix',
    defaultMessage: 'NOK',
    description: 'Suffix text for amounts in the loan calculator etc. kr or sek',
  },
  feesCostRequired: {
    id: 'sambla.no.loanCalculator.feesCostRequired',
    defaultMessage: 'Please enter the fee',
    description: 'Fee description',
  },
  feesCostOnlyDigits: {
    id: 'sambla.no.loanCalculator.feesCostOnlyDigits',
    defaultMessage: 'Please write your fees cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the fees cost.',
  },
  feesCostTooHigh: {
    id: 'sambla.no.loanCalculator.feesCostTooHigh',
    defaultMessage: 'Your fees cost cannot be more than {maxCost, number} NOK/mth.',
    description: 'Inform the customer that we may unfortunatly only accept a fees cost of "x" and that their cost is to high',
  },
  feesCostTooLow: {
    id: 'sambla.no.loanCalculator.feesCostTooLow',
    defaultMessage: 'Please enter the fees connected to the loan',
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  feesCostStartWithZero: {
    id: 'sambla.no.loanCalculator.feesCostStartWithZero',
    defaultMessage: "Please don't start your input with a 0",
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  spouseMonthlyIncomeLabel: {
    id: 'sambla.no.spouseMonthlyIncomeLabel',
    defaultMessage: "Spouse's monthly income before taxes (NOK/mth)",
    description: 'Label for spouse monthly income field',
  },
  spouseAnnualIncomeLabel: {
    id: 'sambla.no.spouseAnnualIncomeLabel',
    defaultMessage: "Spouse's annual income before taxes",
    description: 'Label for spouse monthly income field',
  },
  spouseMonthlyIncomePlaceholder: {
    id: 'sambla.no.spouseMonthlyIncomePlaceholder',
    defaultMessage: "Spouse's monthly income before taxes (NOK/mth)",
    description: 'Placeholder for spouse monthly income field',
  },
  salaryMustBePositive: {
    id: 'sambla.no.salaryMustBePositive',
    defaultMessage: 'Salary must be a positive number',
    description: 'Placeholder for error message if salary is negative',
  },
  monthlyIncomeAfterTaxTooltip: {
    id: 'sambla.no.monthlyIncomeAfterTaxTooltip',
    defaultMessage: 'Please enter your total monthly income from all sources (NOK after taxes). This may include for example salary, commission or pension.',
    description: 'tooltip for monthly income',
  },
  coApplicantMonthlyIncomeAfterTaxTooltip: {
    id: 'sambla.no.coApplicantMonthlyIncomeAfterTaxTooltip',
    defaultMessage: 'Please enter your co-applicants total monthly income from all sources (NOK before taxes). This may include for example salary, commission or pension.',
    description: 'tooltip for monthly co applicant income',
  },
  annualIncomeBeforeTaxTooltip: {
    id: 'sambla.no.annualIncomeBeforeTaxTooltip',
    defaultMessage: 'Please enter your total yearly income from all sources (NOK before taxes). This may include for example salary, commission or pension.',
    description: 'tooltip for monthly income',
  },
  coApplicantAnnualIncomeBeforeTaxTooltip: {
    id: 'sambla.no.coApplicantAnnualIncomeBeforeTaxTooltip',
    defaultMessage: 'Please enter your co-applicants total yearly income from all sources (NOK before taxes). This may include for example salary, commission or pension.',
    description: 'tooltip for monthly co applicant income',
  },
  duplicateApplicationErrorMessage: {
    id: 'sambla.no.submitError.duplicateApplication',
    defaultMessage: 'It looks like you already have a valid application. If you want to make changes to the application, please contact our customer service on',
    description: 'Text for the error message that is displayed to the customer when they already have a active application.',
  },
  errorMessageTitle: {
    id: 'sambla.no.submitError.title',
    defaultMessage: 'Wops!',
    description: 'Title for the error that is displayed on submit.',
  },
  mortgageValidateNumericalityIsRequired: {
    id: 'sambla.no.mortgage.validateNumericality.isRequired',
    defaultMessage: 'This field is required',
    description: 'Error message when a required number field is missing a value',
  },
  mortgageValidateNumericalityOnlyNumbers: {
    id: 'sambla.no.mortgage.validateNumericality.onlyNumbers',
    defaultMessage: 'This field must be a number',
    description: 'Error message when a number field contains a non-numerical value',
  },
  mortgageValidateNumericalityMustBeAbove: {
    id: 'sambla.no.mortgage.validateNumericality.mustBeAbove',
    defaultMessage: 'This field must be greater than {min}',
    description: 'Error message when a number field is too low',
  },
  mortgageValidateNumericalityMustBeBelow: {
    id: 'sambla.no.mortgage.validateNumericality.mustBeBelow',
    defaultMessage: 'This field must be less than {max}',
    description: 'Error message when a number field is too high',
  },
});

export default messages;
