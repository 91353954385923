export default {
  totalAmountMarginTop: '3rem',
  loanCalculatorBorderWidth: '1px',
  loanCalculatorValueColor: 'var(--green)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '.5rem',
  prefilllColor: 'var(--digi-blue-700)',
  prefillBackroundColor: '#EDFFF3',
  validatedBrandColor: 'var(--mpc)',
  buttonText: 'var(--white) !important',
};
